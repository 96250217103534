// ListConfig

<template>
  	<v-container fluid ma-0 pa-0>
        <v-row no-gutters>
        <v-col cols="12" class="pt-0">
        <v-card tile class="grey darken-3 overflow-hidden">
            <v-app-bar absolute dark dense color="blue-grey darken-2"
                scroll-target="#playground" elevate-on-scroll
            >
				<v-toolbar-title>List Config {{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
				<v-btn small class="ml-3" color="success" :disabled="!isDirty" @click="saveData">Save</v-btn>
	  		</v-app-bar>
            <v-sheet id="playground" class="grey darken-3 overflow-y-auto" max-height="700">
				<v-container fluid style="min-height: 700px;padding-top: 60px">
					<v-row>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="ColumnName" dense solo hide-details readonly
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="Title" dense solo hide-details readonly
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="Format" dense solo hide-details readonly
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="Pos" dense solo hide-details readonly reverse
                            ></v-text-field>
                        </v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
                            <v-text-field class="ma-0 pa-0" background-color="grey darken-3" style="font-size: 9pt;"
								value="Width" dense solo hide-details readonly reverse
                            ></v-text-field>
                        </v-col>
					</v-row>
					<v-row v-for="(item, index) in items" :key="index">
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
							<v-text-field class="ma-0 pa-0 text-black" background-color="grey darken-2" height="20" style="font-size: 9pt;"
								:value="item.column_name" dense solo hide-details readonly
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
							<v-text-field class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 9pt;"
								v-model="item.title" dense solo hide-details autocomplete="off" @input="hasChanged"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pt-1 pl-1">
                                <v-select class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 9pt;"
                                v-model="item.format" dense solo hide-details :items="foptions" @change="hasChanged"
                                ></v-select>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
							<v-text-field class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 9pt;"
								v-model="item.pos" dense solo hide-details reverse autocomplete="off" @input="hasChanged"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0 pt-1 pl-1">
							<v-text-field class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 9pt;"
								v-model="item.width" dense solo hide-details reverse autocomplete="off" @input="hasChanged"
							></v-text-field>
						</v-col>
					</v-row>
		  		</v-container>
			</v-sheet>
		</v-card>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
        </v-col>
        </v-row>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'ListConfig',
    components: {
    },
    props: {
        ctx: {
            type: Object, default: () => ({})
        },
    },
    data: () => ({
        loading: false,
        isDirty: false,
        items: [],
        foptions: JSON.parse('["default", "number", "date", "kw", "decimal", "euro", "proz", "lookup"]'),
    }),
    computed: {
        ...mapGetters({
            cfglists: 'configs/getCfgLists',
        }),
		title: function() {
			return JSON.stringify(this.ctx)
		},
    },
    mounted() {
		this.fetchData()
    },
    methods: {
        fetchData() {
            this.loading = true
            let param = this.ctx
            let self = this
            this.$store.dispatch('configs/getCfgLists', param)
            .then((response) => {
                //console.log(this.cfglists)
                this.items = JSON.parse(JSON.stringify(this.cfglists))
                this.items.map(x=>x.format=x.format || 'default')
                self.loading = false
            })
            .catch((error) => {
                console.log(error)
                self.loading = false
            });
        },
        hasChanged() {
            this.isDirty = true
            this.ctx.dirty = true
            //console.log(this.items)
        },
        saveData() {
            let param = {
                ctx: this.ctx,
                cfglists: this.items,
            }
            this.$emit('dying', param)
            this.isDirty = false
        },
    },
    beforeDestroy() {
        let param = {
            ctx: this.ctx,
            cfglists: this.items,
        }
        this.$emit('dying', param)
    },
    watch: {
    },
}
</script>

<style>
.text-black .v-text-field__slot input {
   color: #000 !important;
}
</style>